import { default as _91_46_46_46all_93vUMsruWzocMeta } from "/opt/build/repo/pages/[...all].vue?macro=true";
import { default as address4Pyeh3Gf44Meta } from "/opt/build/repo/pages/account/address.vue?macro=true";
import { default as indexQHLGOCDfydMeta } from "/opt/build/repo/pages/account/index.vue?macro=true";
import { default as orderYTFBIYHl94Meta } from "/opt/build/repo/pages/account/order.vue?macro=true";
import { default as profileN05rytv9oqMeta } from "/opt/build/repo/pages/account/profile.vue?macro=true";
import { default as indexrjCbFOaRH0Meta } from "/opt/build/repo/pages/account/recover/index.vue?macro=true";
import { default as passwordlNZ0D2QY3NMeta } from "/opt/build/repo/pages/account/recover/password.vue?macro=true";
import { default as shoppinglists3TaaIfexGRMeta } from "/opt/build/repo/pages/account/shoppinglists.vue?macro=true";
import { default as appswitchxYGATtrrRPMeta } from "/opt/build/repo/pages/checkout/appswitch.vue?macro=true";
import { default as cartvAIHAgUEQfMeta } from "/opt/build/repo/pages/checkout/cart.vue?macro=true";
import { default as indexz5dTd4TBP7Meta } from "/opt/build/repo/pages/checkout/index.vue?macro=true";
import { default as resumeiHYJITXQC6Meta } from "/opt/build/repo/pages/checkout/resume.vue?macro=true";
import { default as indexOSUfysdLGzMeta } from "/opt/build/repo/pages/checkout/success/[id]/index.vue?macro=true";
import { default as paidGXVyTIcaqqMeta } from "/opt/build/repo/pages/checkout/success/[id]/paid.vue?macro=true";
import { default as unpaidfROsuxPf80Meta } from "/opt/build/repo/pages/checkout/success/[id]/unpaid.vue?macro=true";
import { default as verificationt6hUOn24L1Meta } from "/opt/build/repo/pages/checkout/verification.vue?macro=true";
import { default as errorAgWXU9CPFRMeta } from "/opt/build/repo/pages/error.vue?macro=true";
import { default as loginBXD9sq3T3sMeta } from "/opt/build/repo/pages/login.vue?macro=true";
import { default as registeryZbpxRGV6SMeta } from "/opt/build/repo/pages/register.vue?macro=true";
import { default as reset_45password4iBG212Ge4Meta } from "/opt/build/repo/pages/reset-password.vue?macro=true";
import { default as searchi1lVFWwv2QMeta } from "/opt/build/repo/pages/search.vue?macro=true";
export default [
  {
    name: _91_46_46_46all_93vUMsruWzocMeta?.name ?? "all___dk",
    path: _91_46_46_46all_93vUMsruWzocMeta?.path ?? "/:all(.*)*",
    meta: _91_46_46_46all_93vUMsruWzocMeta || {},
    alias: _91_46_46_46all_93vUMsruWzocMeta?.alias || [],
    redirect: _91_46_46_46all_93vUMsruWzocMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/[...all].vue").then(m => m.default || m)
  },
  {
    name: address4Pyeh3Gf44Meta?.name ?? "account-address___dk",
    path: address4Pyeh3Gf44Meta?.path ?? "/account/address",
    meta: address4Pyeh3Gf44Meta || {},
    alias: address4Pyeh3Gf44Meta?.alias || [],
    redirect: address4Pyeh3Gf44Meta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/account/address.vue").then(m => m.default || m)
  },
  {
    name: indexQHLGOCDfydMeta?.name ?? "account___dk",
    path: indexQHLGOCDfydMeta?.path ?? "/account",
    meta: indexQHLGOCDfydMeta || {},
    alias: indexQHLGOCDfydMeta?.alias || [],
    redirect: indexQHLGOCDfydMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: orderYTFBIYHl94Meta?.name ?? "account-order___dk",
    path: orderYTFBIYHl94Meta?.path ?? "/account/order",
    meta: orderYTFBIYHl94Meta || {},
    alias: orderYTFBIYHl94Meta?.alias || [],
    redirect: orderYTFBIYHl94Meta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/account/order.vue").then(m => m.default || m)
  },
  {
    name: profileN05rytv9oqMeta?.name ?? "account-profile___dk",
    path: profileN05rytv9oqMeta?.path ?? "/account/profile",
    meta: profileN05rytv9oqMeta || {},
    alias: profileN05rytv9oqMeta?.alias || [],
    redirect: profileN05rytv9oqMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/account/profile.vue").then(m => m.default || m)
  },
  {
    name: indexrjCbFOaRH0Meta?.name ?? "account-recover___dk",
    path: indexrjCbFOaRH0Meta?.path ?? "/account/recover",
    meta: indexrjCbFOaRH0Meta || {},
    alias: indexrjCbFOaRH0Meta?.alias || [],
    redirect: indexrjCbFOaRH0Meta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/account/recover/index.vue").then(m => m.default || m)
  },
  {
    name: passwordlNZ0D2QY3NMeta?.name ?? "account-recover-password___dk",
    path: passwordlNZ0D2QY3NMeta?.path ?? "/account/recover/password",
    meta: passwordlNZ0D2QY3NMeta || {},
    alias: passwordlNZ0D2QY3NMeta?.alias || [],
    redirect: passwordlNZ0D2QY3NMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/account/recover/password.vue").then(m => m.default || m)
  },
  {
    name: shoppinglists3TaaIfexGRMeta?.name ?? "account-shoppinglists___dk",
    path: shoppinglists3TaaIfexGRMeta?.path ?? "/account/shoppinglists",
    meta: shoppinglists3TaaIfexGRMeta || {},
    alias: shoppinglists3TaaIfexGRMeta?.alias || [],
    redirect: shoppinglists3TaaIfexGRMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/account/shoppinglists.vue").then(m => m.default || m)
  },
  {
    name: appswitchxYGATtrrRPMeta?.name ?? "checkout-appswitch___dk",
    path: appswitchxYGATtrrRPMeta?.path ?? "/checkout/appswitch",
    meta: appswitchxYGATtrrRPMeta || {},
    alias: appswitchxYGATtrrRPMeta?.alias || [],
    redirect: appswitchxYGATtrrRPMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/checkout/appswitch.vue").then(m => m.default || m)
  },
  {
    name: cartvAIHAgUEQfMeta?.name ?? "checkout-cart___dk",
    path: cartvAIHAgUEQfMeta?.path ?? "/checkout/cart",
    meta: cartvAIHAgUEQfMeta || {},
    alias: cartvAIHAgUEQfMeta?.alias || [],
    redirect: cartvAIHAgUEQfMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/checkout/cart.vue").then(m => m.default || m)
  },
  {
    name: indexz5dTd4TBP7Meta?.name ?? "checkout___dk",
    path: indexz5dTd4TBP7Meta?.path ?? "/checkout",
    meta: indexz5dTd4TBP7Meta || {},
    alias: indexz5dTd4TBP7Meta?.alias || [],
    redirect: indexz5dTd4TBP7Meta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/checkout/index.vue").then(m => m.default || m)
  },
  {
    name: resumeiHYJITXQC6Meta?.name ?? "checkout-resume___dk",
    path: resumeiHYJITXQC6Meta?.path ?? "/checkout/resume",
    meta: resumeiHYJITXQC6Meta || {},
    alias: resumeiHYJITXQC6Meta?.alias || [],
    redirect: resumeiHYJITXQC6Meta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/checkout/resume.vue").then(m => m.default || m)
  },
  {
    name: indexOSUfysdLGzMeta?.name ?? "checkout-success-id___dk",
    path: indexOSUfysdLGzMeta?.path ?? "/checkout/success/:id()",
    meta: indexOSUfysdLGzMeta || {},
    alias: indexOSUfysdLGzMeta?.alias || [],
    redirect: indexOSUfysdLGzMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/checkout/success/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: paidGXVyTIcaqqMeta?.name ?? "checkout-success-id-paid___dk",
    path: paidGXVyTIcaqqMeta?.path ?? "/checkout/success/:id()/paid",
    meta: paidGXVyTIcaqqMeta || {},
    alias: paidGXVyTIcaqqMeta?.alias || [],
    redirect: paidGXVyTIcaqqMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/checkout/success/[id]/paid.vue").then(m => m.default || m)
  },
  {
    name: unpaidfROsuxPf80Meta?.name ?? "checkout-success-id-unpaid___dk",
    path: unpaidfROsuxPf80Meta?.path ?? "/checkout/success/:id()/unpaid",
    meta: unpaidfROsuxPf80Meta || {},
    alias: unpaidfROsuxPf80Meta?.alias || [],
    redirect: unpaidfROsuxPf80Meta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/checkout/success/[id]/unpaid.vue").then(m => m.default || m)
  },
  {
    name: verificationt6hUOn24L1Meta?.name ?? "checkout-verification___dk",
    path: verificationt6hUOn24L1Meta?.path ?? "/checkout/verification",
    meta: verificationt6hUOn24L1Meta || {},
    alias: verificationt6hUOn24L1Meta?.alias || [],
    redirect: verificationt6hUOn24L1Meta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/checkout/verification.vue").then(m => m.default || m)
  },
  {
    name: errorAgWXU9CPFRMeta?.name ?? "error___dk",
    path: errorAgWXU9CPFRMeta?.path ?? "/error",
    meta: errorAgWXU9CPFRMeta || {},
    alias: errorAgWXU9CPFRMeta?.alias || [],
    redirect: errorAgWXU9CPFRMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/error.vue").then(m => m.default || m)
  },
  {
    name: loginBXD9sq3T3sMeta?.name ?? "login___dk",
    path: loginBXD9sq3T3sMeta?.path ?? "/login",
    meta: loginBXD9sq3T3sMeta || {},
    alias: loginBXD9sq3T3sMeta?.alias || [],
    redirect: loginBXD9sq3T3sMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/login.vue").then(m => m.default || m)
  },
  {
    name: registeryZbpxRGV6SMeta?.name ?? "register___dk",
    path: registeryZbpxRGV6SMeta?.path ?? "/register",
    meta: registeryZbpxRGV6SMeta || {},
    alias: registeryZbpxRGV6SMeta?.alias || [],
    redirect: registeryZbpxRGV6SMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/register.vue").then(m => m.default || m)
  },
  {
    name: reset_45password4iBG212Ge4Meta?.name ?? "reset-password___dk",
    path: reset_45password4iBG212Ge4Meta?.path ?? "/reset-password",
    meta: reset_45password4iBG212Ge4Meta || {},
    alias: reset_45password4iBG212Ge4Meta?.alias || [],
    redirect: reset_45password4iBG212Ge4Meta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/reset-password.vue").then(m => m.default || m)
  },
  {
    name: searchi1lVFWwv2QMeta?.name ?? "search___dk",
    path: searchi1lVFWwv2QMeta?.path ?? "/search",
    meta: searchi1lVFWwv2QMeta || {},
    alias: searchi1lVFWwv2QMeta?.alias || [],
    redirect: searchi1lVFWwv2QMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/search.vue").then(m => m.default || m)
  }
]