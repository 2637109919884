import * as Sentry from '@sentry/vue'

/**
 * @see https://www.lichter.io/articles/nuxt3-sentry-recipe/ on implementation
 */
export default defineNuxtPlugin((nuxtApp) => {
    const router = useRouter()
    const { public: { sentry } } = useRuntimeConfig()

    if (!sentry.dsn) {
        return
    }

    const ignoreErrors = [
        'Unable to preload CSS', // Vite issue fixed in newer versions. Supressing for now.
        'error loading dynamically imported module',
        'Failed to fetch dynamically imported module',
        'Unreachable hosts - your application id may be incorrect. If the error persists, contact support@algolia.com.', // Algolia search
        'https://static-forms.klaviyo.com/forms/api/v7/MgCWaz', // Klaviyo form failures, third-party we can't control.
        'InitializationError', // Also Klaviyo off-site script
    ]

    Sentry.init({
        app: nuxtApp.vueApp,
        dsn: sentry.dsn,
        environment: sentry.environment,
        sendDefaultPii: true,
        attachStacktrace: true,
        integrations: [
            Sentry.browserProfilingIntegration(),
            Sentry.browserTracingIntegration({ router }),
            Sentry.httpClientIntegration(),
            Sentry.vueIntegration({ Vue: nuxtApp.vueApp }),
            Sentry.captureConsoleIntegration({
                levels: ['error']
            }),
            Sentry.extraErrorDataIntegration({
                depth: 6
            }),
            Sentry.moduleMetadataIntegration(),
            // Sentry.replayIntegration({ // This shit makes browser stall.
            //     maskAllText: false,
            //     blockAllMedia: false,
            // }),
        ],
        tracesSampleRate: 0.1,
        tracePropagationTargets: [
            'https://bevco.dk',
            'https://bevco-staging.netlify.app'
        ],
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 0.1,
        ignoreErrors: ignoreErrors
    })
})
