export default defineNuxtRouteMiddleware((to, from) => {
    // Aint no window on server
    if (import.meta.server) return;

    useNuxtApp().hook("page:finish", () => {
        if (history.state.scroll) {
            setTimeout(() => window.scrollTo(history.state.scroll), 0);
        } else {
            setTimeout(() => window.scrollTo(0, 0), 0);
        }
    });
})
