// @ts-nocheck


export const localeCodes = [
  "dk"
]

export const localeMessages = { 
  "dk": [
  ],
}

export const resolveNuxtI18nOptions = async (context) => {
  const nuxtI18nOptions = {
  "experimental": {
    "jsTsFormatResource": false
  },
  "bundle": {
    "compositionOnly": true,
    "runtimeOnly": false,
    "fullInstall": true,
    "dropMessageCompiler": false
  },
  "compilation": {
    "jit": true,
    "strictMessage": true,
    "escapeHtml": false
  },
  "customBlocks": {
    "defaultSFCLang": "json",
    "globalSFCScope": false
  },
  "vueI18n": "./i18n.config.ts",
  "locales": [
    "dk"
  ],
  "defaultLocale": "dk",
  "defaultDirection": "ltr",
  "routesNameSeparator": "___",
  "trailingSlash": false,
  "defaultLocaleRouteNameSuffix": "default",
  "strategy": "prefix_except_default",
  "lazy": false,
  "langDir": null,
  "rootRedirect": null,
  "detectBrowserLanguage": {
    "alwaysRedirect": false,
    "cookieCrossOrigin": false,
    "cookieDomain": null,
    "cookieKey": "i18n_redirected",
    "cookieSecure": false,
    "fallbackLocale": "",
    "redirectOn": "root",
    "useCookie": true
  },
  "differentDomains": false,
  "baseUrl": "",
  "dynamicRouteParams": false,
  "customRoutes": "page",
  "pages": {},
  "skipSettingLocaleOnNavigate": false,
  "types": "composition",
  "debug": false,
  "parallelPlugin": false,
  "messages": {
    "dk": {
      "general": {
        "or": "eller",
        "save": "Gem",
        "saveDiscount": "Spar",
        "pcs": "stk.",
        "perPiece": "/stk.",
        "iAccept": "Jeg accepterer ",
        "terms": "handelsbetingelser",
        "change": "Skift",
        "close": "Luk",
        "goHome": "Gå til forside",
        "frontpage": "Forside",
        "search": "Søg...",
        "loading": "Indlæser...",
        "readMore": "Læs mere",
        "showMore": "Vis mere",
        "readDescription": "Læs mere",
        "readLess": "Læs mindre",
        "addToCart": "Tilføj til kurv",
        "home": "Hjem",
        "total": "Total",
        "totalLower": " total",
        "fail": "Noget gik galt.",
        "contactUs": "Kontakt os",
        "error": "Beklager, der opstod en fejl. Prøv igen senere.",
        "withTax": "inkl. moms",
        "withoutTax": "ekskl. moms",
        "calculatedTax": "Heraf moms",
        "from": "Fra ",
        "newsletter": "Jeg vil gerne opdateres via e-mail",
        "seeAll": "Se alt",
        "edit": "Redigér",
        "editCart": "Redigér kurv",
        "products": "produkter",
        "days": {
          "today": "i dag",
          "tomorrow": "i morgen",
          "monday": "mandag",
          "tuesday": "tirsdag",
          "wednesday": "onsdag",
          "thursday": "torsdag",
          "friday": "fredag",
          "saturday": "lørdag",
          "sunday": "søndag"
        }
      },
      "forms": {
        "generalError": "Udfyld korrekt adresse",
        "errorInvalid": "Ugyldig værdi",
        "errorRequired": "Skal udfyldes",
        "errorEmail": "Ikke en gyldig email adresse",
        "errorMinLength": "Mindst {length} tegn",
        "errorInteger": "Skal indeholde tal"
      },
      "account": {
        "generalError": "Noget gik galt. Tjek dine adresseoplysninger.",
        "emailExists": "Der findes allerede en konto med denne email",
        "cvr": "CVR",
        "phone": "Telefonnummer",
        "myAccount": "Min konto",
        "view": "Vis",
        "hide": "Skjul",
        "logIn": "Log ind",
        "logInTitle": "Log ind eller opret konto",
        "logOut": "Log ud",
        "loginError": "Forkert brugernavn eller adgangskode.",
        "loginSuccess": "Du er logget ind",
        "company": "Firmanavn",
        "vat": "CVR Nr.",
        "private": "Privat",
        "business": "Erhverv",
        "loggedIn": "logged-in",
        "youreloggedIn": "Du er nu logget ind.",
        "salutation": "Salutation",
        "chooseSalutation": "Choose salutation...",
        "newAddress": "Ny adresse",
        "firstName": "Fornavn",
        "namePlaceholder": "Indtast fornavn",
        "lastName": "Efternavn",
        "lastNamePlaceholder": "Indtast efternavn",
        "email": "E-mail",
        "emailPlaceholder": "Indtast email",
        "emailPlaceholderLong": "Indtast din e-mail",
        "phonePlaceholder": "Indtast telefonnummer",
        "address": "Adresse",
        "streetPlaceholder": "Indtast adresse",
        "zip": "Postnr.",
        "zipPlaceholder": "Indtast postnr.",
        "city": "By",
        "cityPlaceholder": "Indtast by",
        "vatPlaceholder": "Indtast CVR",
        "companyPlaceholder": "Indtast firmanavn",
        "country": "Land",
        "invoiceEmails": "Faktura emails",
        "password": "Adgangskode",
        "passwordPlaceholder": "Indtast din adgangskode",
        "createAccount": "Opret en konto for hurtigere køb næste gang.",
        "chooseCountry": "Vælg land...",
        "guest": "guest",
        "newBillingAddress": "Anden faktureringsadresse",
        "shippingAddress": "Leveringsadresse",
        "billingAddress": "Faktureringsadresse",
        "overview": "Overblik",
        "orders": "Ordrer",
        "profile": "Din Profil",
        "addresses": "Adresser",
        "myOverview": "Kontooverblik",
        "customerAccount": "Kundekonto",
        "hello": "Hej,",
        "overviewText": "Her er et overblik over dine seneste kontoinformationer, som du nemt kan slette eller redigere.",
        "orderOverview": "Ordreoversigt",
        "latestOrders": "Seneste ordrer",
        "goToOrders": "Gå til ordreoversigt",
        "loading": "Indlæser ordrer...",
        "reorder": "Genbestil",
        "orderStatus": "Status",
        "orderTotal": "Total",
        "orderDate": "Ordredato",
        "orderDeliveryDate": "Forventet leveringsdato",
        "personalData": "Personlige oplysninger",
        "confirmEmail": "Bekræft email",
        "saveSuccess": "Dine oplysninger er gemt.",
        "loginData": "Login data",
        "emailAddress": "Email adresse",
        "changePassword": "Skift adgangskode",
        "passwordUpdateSuccess": "Din adgangskode er opdateret.",
        "currentPassword": "Din adgangskode",
        "newPassword": "Ny adgangskode",
        "confirmNewPassword": "Bekræft ny adgangskode",
        "repeatPassword": "Gentag adgangskode",
        "defaultAddress": "Standard adresse",
        "defaultShippingAddress": "Standard leveringsadresse",
        "defaultBillingAddress": "Standard faktureringsadresse",
        "setDefaultShippingAddress": "Sæt som standard leveringsadresse",
        "setDefaultBillingAddress": "Sæt som standard faktureringsadresse",
        "addAddress": "Tilføj ny adresse",
        "editAddress": "Rediger adresse",
        "signUp": "Opret en konto",
        "forgotPassword": "Glemt adgangskode?",
        "signUpSuccess": "Din konto er oprettet.",
        "noOrders": "Du har ingen ordrer.",
        "logout": "Log ud",
        "alreadyHaveAccount": "Har du allerede en konto?",
        "noShoppingLists": "Du har ingen indkøbslister.",
        "createShoppingList": "Opret en indkøbsliste",
        "shoppingListName": "Indkøbsliste navn",
        "selectShoppingList": "Vælg en indkøbsliste",
        "isDefault": "Standard liste",
        "deleteShoppingList": "Slet indkøbsliste",
        "resendPassword": "Send adgangskode",
        "recoverPasswordSuccess": "Vi har sendt dig en e-mail med et link til at nulstille din adgangskode.",
        "resetPasswordSuccess": "Din adgangskode er blevet nulstillet.",
        "signup": "Opret en konto",
        "recoverGoToLogin": "Gå tilbage til login",
        "recoverPassword": "Glemt adgangskode"
      },
      "shoppingLists": {
        "title": "Indkøbslister",
        "createSuccess": "Indkøbsliste oprettet.",
        "createError": "Der opstod en fejl ved oprettelse af indkøbsliste.",
        "getError": "Fejl ved indlæsning af indkøbslister",
        "addError": "Kunne ikke tilføje produkt til listen.",
        "updateError": "Kunne ikke opdatere indkøbsliste.",
        "productAddedToCart": "Produktet er tilføjet til indkøbskurven.",
        "addSuccess": "Produkt tilføjet til indkøbsliste.",
        "updateSuccess": "Indkøbsliste opdateret.",
        "productNotAddedToCart": "Kunne ikke tilføje produkt til indkøbskurv.",
        "deleteSuccess": "Indkøbsliste slettet.",
        "productDeleteSuccess": "Produkt fjernet fra listen.",
        "productDeleteError": "Fejl ved fjernelse af produkt.",
        "deleteError": "Fejl ved sletning af indkøbsliste.",
        "noProducts": "Der er endnu ingen produkter på denne liste.",
        "findAndAdd": "Tilføj produkter til din indkøbsliste",
        "addToShoppingList": "Tilføj til indkøbsliste"
      },
      "cart": {
        "title": "Din indkøbskurv",
        "empty": "Din indkøbskurv er tom.",
        "goToCartPage": "Gå til indkøbskurv.",
        "continueShopping": "Fortsæt med at handle",
        "checkout": "Gå til kassen",
        "checkoutNewWindow": " (åbner i nyt vindue)",
        "couponCode": "Jeg har et gavekort eller rabatkode",
        "shippingCalculation": "Forsendelse og afgifter beregnes ved kassen.",
        "subtotal": "Subtotal",
        "remove": "Fjern vare fra kurv",
        "orderSummary": "Ordreoversigt",
        "orderTotal": "Total",
        "shippingEstimate": "Levering",
        "taxRate": "Moms",
        "withoutTax": "Ekskl. moms",
        "total": "Total",
        "youHave": "Du har",
        "itemsInCart": "varer i din indkøbskurv.",
        "others": "Øvrige",
        "deposit": "Pant",
        "wareDate": "Datovare:",
        "minDate": "Mindst holdbar til ",
        "addCoupon": "Indløs gavekort eller voucher",
        "recommendedProducts": "Måske det her vil falde i din smag?"
      },
      "checkout": {
        "logInText": "Har du allerede en konto?",
        "error": "Der opstod en fejl.",
        "errorPlacingOrder": "Der opstod en fejl ved gennemførelse af din ordre.",
        "errorDateWeekend": "Levering er ikke mulig i weekenden. Vælg venligst en ny leveringsdato.",
        "errorDateDisabled": "Levering er ikke mulig på den valgte dato. Vælg venligst en ny leveringsdato.",
        "errorDateTooLate": "Leveringsdatoen kan ikke overskride 80 dage fra dags dato.",
        "errorDateTooLateMht": "Leveringsdatoen kan ikke overskride 5 dage, når ordren indeholder datovarer.",
        "errorDateRequired": "Vælg venligst en leveringsdato.",
        "sameAddress": "Levering til samme adresse",
        "deliveryTo": "Levering til ",
        "orderForCompany": "Skal du bestille til firma?",
        "continue": "Fortsæt",
        "shippingAddress": "Leveringsadresse",
        "newBillingAddress": "Ny faktureringsadresse",
        "newShippingAddress": "Ny leveringsadresse",
        "choosePakkeshop": "Vælg pakkeshop",
        "chooseAnotherParcelShop": "Vælg en anden pakkeshop",
        "chosenParcelshop": "Valgt pakkeshop",
        "parcelShopError": "Vælg venligst en pakkeshop.",
        "ean": "EAN-nr",
        "reference": "Reference",
        "promotion": {
          "code_added": "Rabatkode tilføjet"
        },
        "newsletter": {
          "signup": "Hold mig opdateret med særlige kampagner og tilbud",
          "discounts": "Særlige rabatter",
          "exclusive": "Kun for signups",
          "recommendations": "Personlige anbefalinger",
          "interests": "Baseret på dine interesser",
          "first": "Førsteret",
          "news": "Til nyheder og lager-begrænset tilbud",
          "signupButton": "Tilmeld"
        },
        "shipping": {
          "title": "Fragt",
          "select": "Vælg fragt.",
          "time": "Forventet leveringstid:",
          "disclaimer": "Din ordre kan ikke sendes til pakkeboks pga. vægt eller skrøbelighed.",
          "price": "Pris:",
          "priceFrom": "Pris fra:",
          "deliveryWish": "Ønsket Leveringsdato",
          "deliveryWishPlaceholder": "Vælg dato...",
          "withoutReceipt": "Må stilles uden kvittering",
          "receiptDescription": "Er du ikke på adressen mellem kl. 08.00 og 16.00, kan vi stille din ordre uden kvittering - HUSK at dette sker på eget ansvar.",
          "deliveryToCurb": "Ordren leveres til kantsten.",
          "phoneNotification": "Telefonisk varsling",
          "phoneDescription": "Vil du gerne ringes op ca. 30 min. før dine varer ankommer?",
          "earlyMorning": "Tidsbestemt levering før kl. 09.00",
          "lateMorning": "Tidsbestemt levering før kl. 12.00",
          "zip": "Indtast postnummer...",
          "search": "Søg",
          "estimatedShort": "Forventet levering {day}",
          "estimatedCountdown": "Sendes om {countdown}",
          "estimatedSoldOut": "Forventet levering 5-7 hverdage",
          "countdownFallback": "Sendes i dag - Forventet levering {day}"
        },
        "cartItemRemoved": "Vare fjernet fra indkøbskurv.",
        "placeOrder": "Gennemfør ordre på",
        "payment": {
          "title": "Betalingsmetode",
          "selection": "Vælg betalingsmetode."
        },
        "paid": {
          "titleNoUnderline": "Din order",
          "titleUnderline": "er modtaget!",
          "body": "Vi går snart igang med at pakke din ordre, og vi giver dig besked så snart den er afsendt fra vores lager. Tak fordi du handlede hos os!"
        },
        "unpaid": {
          "titleNoUnderline": "Din betaling",
          "titleUnderline": "gik ikke igennem!",
          "body": "Der opstod desværre en fejl med betaling af din ordre. Kontakt os for nærmere information."
        },
        "accountInformation": {
          "registered": "Tak for din tilmelding! Du vil snart modtage en bekræftelsesmail. Klik på linket i den for at fuldføre tilmeldingen."
        },
        "giftcard": {
          "no_balance": "Ingen rest tilbage i gavekortet",
          "is_expired": "Gavekort er ikke længere gyldigt",
          "already_in_use": "Gavekortet er allerede i kurven",
          "error_default": "Gavekortet kunne ikke tilføjes til kurven",
          "code_added": "Gavekortet er tilføjet til kurven"
        },
        "success": {
          "title": "Tak for din bestilling!",
          "thanks": "Tak for dit køb, ",
          "received": "Din ordre er nu modtaget.",
          "ordernumber": "Dit ordrenummer er",
          "confirmationNotice": "Vi har sendt en ordrebekræftelse til",
          "seeOrder": "Se din ordre",
          "billingAddress": "Faktureringsadresse",
          "shippingAddress": "Leveringsadresse",
          "orderInformation": "Information",
          "paymentMethod": "Betalingsmetode",
          "shippingMethod": "Forsendelsesmetode",
          "quantity": "Antal",
          "pricePerUnit": "Pris/stk",
          "subtotal": "Subtotal",
          "shippingPrice": "Fragt",
          "taxPrice": "Moms (25%)",
          "orderTotal": "Total",
          "no-data": "Ingen ordre-data fundet"
        },
        "ageVerificationFailed": "Din alder blev ikke verificeret. Prøv igen.",
        "paymentFailed": "Din betaling gik ikke igennem. Prøv igen."
      },
      "promo": {
        "title": "Rabatkode",
        "discount": "Rabat",
        "appliedCodesText": "Aktive rabatkoder: ",
        "placeholder": "Indtast din kode her",
        "removeBtn": "Fjern",
        "linePrefix": "Rabat: ",
        "notFound": "Rabatkoden er ugyldig.",
        "activate": "Aktiver",
        "excluded": "Rabatkoden kan ikke bruges sammen med andre rabatkoder.",
        "notElegible": "Rabatkoden kan ikke bruges sammen med de produkter i din indkøbskurv."
      },
      "listing": {
        "seeAll": "Se alle",
        "buyNow": "Tilføj til kurv",
        "tilbud": "Tilbud",
        "showTilbud": "Vis kun tilbud",
        "soldoutBtn": "Udsolgt",
        "loadMore": "Vis flere produkter",
        "loadPrevious": "Vis tidligere",
        "sortBy": "Sortér:",
        "clearFilters": "Nulstil alle",
        "filter": "Filtrer",
        "items": "Varer",
        "grape": "Drue",
        "categories": "Kategori",
        "year": "Årgang",
        "cert": "Certificering",
        "country": "Land",
        "region": "Region",
        "brand": "Mærke",
        "alcohol": "Alkohol",
        "packaging": "Emballage",
        "producer": "Leverandør",
        "size": "Størrelse",
        "results": "Resultater",
        "recommended": "Anbefalet",
        "prisAsc": "Pris (stigende)",
        "prisDesc": "Pris (faldende)",
        "nameAsc": "Navn (A-Z)",
        "nameDesc": "Navn (Z-A)",
        "topseller": "Populære",
        "popularCategories": "Populære kategorier",
        "chooseCategory": "Vælg kategori",
        "manu": "Producent",
        "go": "Søg",
        "inStock": "På lager",
        "amountInStock": " på lager - Hurtig levering",
        "outOfStock": "5-7 dages levering",
        "outOfStockShort": "5-7 dage",
        "backorder_qty": "5-7 dage ({quantity} på lager)",
        "soldOut": "Udsolgt",
        "stockExceeded": "Kun {quantity} på lager",
        "limitedOffer": "Max. {quantity} stk. til tilbudspris",
        "fixedLowPrice": "Fast lav pris",
        "meta": {
          "title": "Køb {name} | Billige Priser & Hurtig Levering",
          "description": "Vil du købe {name} online? På Bevco.dk finder du alt i drikkevarer til skarpe priser med dag-til-dag levering.",
          "numberOfProducts": "Vælg mellem {totalProducts} varer."
        },
        "tierAfter": " v/ {qty} stk.",
        "seeMore": "Se flere"
      },
      "product": {
        "number": "Varenummer",
        "frequentlyBoughtTogether": "Andre kunder købte også",
        "recommendedProducts": "Anbefalede produkter",
        "oneUnit": " STK.",
        "nutritionPer100": "Næringsindhold pr. 100 ",
        "description": "Beskrivelse",
        "properties": "Specifikationer",
        "declaration": "Varedeklaration",
        "allergens": "Allergener",
        "soldoutTitle": "Udsolgt",
        "soldoutText": "Vinen er desværre udsolgt, for info om hvornår den kommer tilbage kan du kontakte os på ",
        "notAvilableText": "Da vinene fra denne producent kommer i små volumener bliver de solgt via fordeling.",
        "notAvailableTextContact": "Kontakt os for at høre mere om hvordan du kan få adgang til disse vine.",
        "sufur_and_sulfites": "Svovldioxid og sulfitter",
        "humle": "Humle",
        "egg": "Æg",
        "gluten": "Glutenholdigt korn",
        "crustaceans": "Krebsdyr",
        "fish": "Fisk",
        "milk": "Mælk",
        "peanuts": "Jordnødder/peanuts",
        "soja": "Soja",
        "nuts": "Nødder",
        "selleri": "Selleri",
        "sennep": "Sennep",
        "sesame_seeds": "Sesamfrø",
        "lupine": "Lupin",
        "molluscs": "Bløddyr",
        "inStock": "På lager",
        "expiringProduct": "Datovare",
        "fewInStock": "Få på lager",
        "outOfStock": "5-7 dages levering",
        "soldOut": "Udsolgt",
        "limitedOffer": "Max. {quantity} stk. til denne pris",
        "lowCaffeine": "Indeholder koffein. Bør ikke indtages af børn eller gravide",
        "highCaffeine": "Højt koffeinindhold. Bør ikke indtages af børn, gravide eller ammende kvinder",
        "mht-badge": {
          "mht4": "Dato over&shy;skredet",
          "mht2": "Kort hold&shy;barhed",
          "mht1": "Tjek hold&shy;barhed"
        },
        "information": "Produkt information",
        "meta": {
          "title": "Køb {name}",
          "description": "Vil du købe {name} online? På Bevco.dk finder du alt i drikkevarer til skarpe priser med dag-til-dag levering."
        }
      },
      "landing": {
        "meta": {
          "title": "",
          "description": ""
        }
      },
      "search": {
        "noResults": "Ingen resultater fundet.",
        "searchPlaceholder": "Hvad er du på udkig efter?",
        "cmsSearchPlaceholder": "Søg blandt mere end 3.500 varer",
        "resultCount": "Ingen resultater fundet | Se resultat | Se alle {count} resultater"
      },
      "order": {
        "ordernumber": "Ordrenummer",
        "totalAmount": "Totalbeløb",
        "status": "Status",
        "date": "Ordredato",
        "product": "Produkt",
        "quantity": "Antal",
        "price": "Pris",
        "subtotal": "Subtotal",
        "yourOrder": "Din ordre #",
        "hasBeenPlaced": "er afsendt og vil snart være hos dig.",
        "completePayment": "Gennemfør betaling.",
        "redirecting": "Du vil blive omdirigeret til betalingsgatewayen på 5 sekunder.",
        "goToPayment": "Gå til betaling"
      },
      "Value is required": "Skal udfyldes",
      "Value is not a valid email address": "Ikke en gyldig email adresse",
      "The value must be equal to the other value": "Skal være ens",
      "This field should be at least 8 characters long": "Skal være mindst 8 tegn langt",
      "This field should be at least 3 characters long": "Skal være mindst 3 tegn langt",
      "ageConsent": {
        "title": "Er du 18 år eller over?",
        "body": "Du skal være fyldt 18 år for at tilgå indholdet på dette website.",
        "btnYes": "Ja",
        "btnNo": "Nej",
        "error": "Du har desværre ikke ret til at handle på dette website."
      },
      "footer": {
        "contact": "Kontakt kundeservice",
        "shopMore": "Shop kategorier",
        "information": "Information",
        "customerService": "Kundeservice",
        "openingTimes": "Åbningstid for kundeservice:",
        "weekdays": "Alle hverdage fra kl. 9:00-16:00"
      },
      "pageNotFound": {
        "title": "Siden blev ikke fundet",
        "text": "Siden du leder efter findes ikke. Prøv at gå tilbage til forsiden eller kontakt os for hjælp."
      },
      "error": {
        "notFoud": "Siden blev ikke fundet.",
        "logLoad": "API'en tager for lang tid at svare.",
        "serverError": "Noget gik galt.",
        "incompleteRequest": "Serveren kunne ikke fuldføre din anmodning. Prøv venligst igen om få sekunder.",
        "busyServer": "Serveren kunne ikke fuldføre din anmodning. Prøv venligst igen om få sekunder.",
        "error": "Fejl",
        "cvrFail": "Vi kunne ikke hente dine oplysninger automatisk.",
        "cvrRequired": "Indtast venligst CVR"
      },
      "products_nutrition_fat": "Fedt",
      "products_nutrition_energy": "Energi",
      "products_nutrition_fatacids": "Heraf mættede fedtsyrer",
      "products_nutrition_carbohydrates": "Kulhydrat",
      "products_nutrition_sugar": "Heraf sukkerarter",
      "products_nutrition_protine": "Protein",
      "products_nutrition_salt": "Salt",
      "products_nutrition_koffein": "Koffeinindhold (mg)",
      "1-3 days": "1-3 dage"
    }
  },
  "i18nModules": []
}
  
  const vueI18nConfigLoader = async loader => {
    const config = await loader().then(r => r.default || r)
    if (typeof config === 'object') return config
    if (typeof config === 'function') return await config()
    return {}
  }

  const deepCopy = (src, des, predicate) => {
    for (const key in src) {
      if (typeof src[key] === 'object') {
        if (!(typeof des[key] === 'object')) des[key] = {}
        deepCopy(src[key], des[key], predicate)
      } else {
        if (predicate) {
          if (predicate(src[key], des[key])) {
            des[key] = src[key]
          }
        } else {
          des[key] = src[key]
        }
      }
    }
  }
  
  const mergeVueI18nConfigs = async (loader) => {
    const layerConfig = await vueI18nConfigLoader(loader)
    const cfg = layerConfig || {}
    
    for (const [k, v] of Object.entries(cfg)) {
      if(nuxtI18nOptions.vueI18n?.[k] === undefined || typeof nuxtI18nOptions.vueI18n?.[k] !== 'object') {
        nuxtI18nOptions.vueI18n[k] = v
      } else {
        deepCopy(v, nuxtI18nOptions.vueI18n[k])
      }
    }
  }

  nuxtI18nOptions.vueI18n = { messages: {} }
  
  await mergeVueI18nConfigs(() => import("../i18n.config.ts?hash=bffaebcb&config=1" /* webpackChunkName: __i18n_config_ts_bffaebcb */)) 
    
  return nuxtI18nOptions
}

export const nuxtI18nOptionsDefault = {
  "experimental": {
    "jsTsFormatResource": false
  },
  "bundle": {
    "compositionOnly": true,
    "runtimeOnly": false,
    "fullInstall": true,
    "dropMessageCompiler": false
  },
  "compilation": {
    "jit": true,
    "strictMessage": true,
    "escapeHtml": false
  },
  "customBlocks": {
    "defaultSFCLang": "json",
    "globalSFCScope": false
  },
  "vueI18n": "",
  "locales": [],
  "defaultLocale": "",
  "defaultDirection": "ltr",
  "routesNameSeparator": "___",
  "trailingSlash": false,
  "defaultLocaleRouteNameSuffix": "default",
  "strategy": "prefix_except_default",
  "lazy": false,
  "langDir": null,
  "rootRedirect": null,
  "detectBrowserLanguage": {
    "alwaysRedirect": false,
    "cookieCrossOrigin": false,
    "cookieDomain": null,
    "cookieKey": "i18n_redirected",
    "cookieSecure": false,
    "fallbackLocale": "",
    "redirectOn": "root",
    "useCookie": true
  },
  "differentDomains": false,
  "baseUrl": "",
  "dynamicRouteParams": false,
  "customRoutes": "page",
  "pages": {},
  "skipSettingLocaleOnNavigate": false,
  "types": "composition",
  "debug": false,
  "parallelPlugin": false
}

export const nuxtI18nInternalOptions = {
  "__normalizedLocales": [
    {
      "code": "dk",
      "iso": "da-DK",
      "files": []
    }
  ]
}
 
export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const parallelPlugin = false
export const isSSG = false
