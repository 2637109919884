import revive_payload_client_4sVQNw7RlN from "/opt/build/repo/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/opt/build/repo/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/opt/build/repo/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/opt/build/repo/node_modules/nuxt/dist/app/plugins/payload.client.js";
import plugin_vue3_A0OWXRrUgq from "/opt/build/repo/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/opt/build/repo/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/opt/build/repo/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_tbFNToZNim from "/opt/build/repo/node_modules/@zadigetvoltaire/nuxt-gtm/dist/runtime/plugin.mjs";
import unocss_MzCDxu9LMj from "/opt/build/repo/.nuxt/unocss.mjs";
import shopware_plugin_txRT4f0fRY from "/opt/build/repo/.nuxt/runtime/shopware.plugin.mjs";
import plugin_ghbUAjaD3n from "/opt/build/repo/node_modules/@nuxtjs/device/dist/runtime/plugin.js";
import plugin_BqLkNUef68 from "/opt/build/repo/node_modules/@nuxtjs/algolia/dist/runtime/plugin.mjs";
import composition_sLxaNGmlSL from "/opt/build/repo/node_modules/@nuxtjs/i18n/dist/runtime/plugins/composition.mjs";
import i18n_yfWm7jX06p from "/opt/build/repo/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import chunk_reload_client_UciE0i6zes from "/opt/build/repo/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import ssr_plugin_B4ptqVdIfe from "/opt/build/repo/node_modules/@vueuse/nuxt/ssr-plugin.mjs";
import sentry_client_shVUlIjFLk from "/opt/build/repo/plugins/sentry.client.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_tbFNToZNim,
  unocss_MzCDxu9LMj,
  shopware_plugin_txRT4f0fRY,
  plugin_ghbUAjaD3n,
  plugin_BqLkNUef68,
  composition_sLxaNGmlSL,
  i18n_yfWm7jX06p,
  chunk_reload_client_UciE0i6zes,
  ssr_plugin_B4ptqVdIfe,
  sentry_client_shVUlIjFLk
]